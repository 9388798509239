<template>
  <div class="pl-1 pr-1">
    <!--    <b-card-text-->
    <!--      style="margin-bottom: 0px;"-->
    <!--      class="mb-1"-->
    <!--    >-->
    <!--      <strong>Permission</strong>-->
    <!--    </b-card-text>-->
    <b-row class="align-items-center no-gutters">
      <b-col
        cols="4"
        sm="3"
      >
        <div>
          {{ $t('all') }}
        </div>
      </b-col>
      <b-col
        cols="8"
        sm="7"
      >
        <div
          class="inlineSpace"
        >
          <b-form-checkbox
            v-model="master"
            :checked="master"
            name="check-button"
            switch
            inline
          />
        </div>
      </b-col>
    </b-row>
    <b-row
      v-for="(v,k) in defaultAbility"
      :key="k"
      class="align-items-center mt-50 mb-50 no-gutters"
    >
      <b-col
        cols="4"
        sm="3"
      >
        <div>
          {{ $t(k) }}
        </div>
      </b-col>
      <b-col
        cols="8"
        sm="9"
      >
        <div
          class="inlineSpace"
        >
          <b-form-radio
            v-model="v.value"
            :disabled="master"
            :name="k"
            class="custom-control-secondary"
            value="0"
            :style="{'pointer-events': master ? 'none' : 'unset' }"
          >
            {{ $t('off') }}
          </b-form-radio>
          <b-form-radio
            v-model="v.value"
            :disabled="!v.haveView || master"
            :name="k"
            class="custom-control-primary"
            value="1"
            :style="{'pointer-events': master ? 'none' : 'unset' }"
          >
            {{ $t('view') }}
          </b-form-radio>
          <b-form-radio
            v-model="v.value"
            :disabled="!v.haveEdit || master"
            :name="k"
            class="custom-control-success"
            value="2"
            :style="{'pointer-events': !v.haveEdit || master ? 'none' : 'unset' }"
          >
            {{ $t('edit') }}
          </b-form-radio>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormRadio, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormRadio,
    BFormCheckbox,
  },
  props: {
    ability: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      master: false,
      defaultAbility: {
        dashboard: {
          haveEdit: false,
          haveView: true,
          value: 0,
        },
        summary: {
          haveView: true,
          haveEdit: false,
          value: 0,
        },
        member: {
          haveView: true,
          haveEdit: true,
          value: 0,
        },
        invoice: {
          haveView: true,
          haveEdit: true,
          value: 0,
        },
        report: {
          haveView: true,
          haveEdit: false,
          value: 0,
        },
        lotto: {
          haveView: true,
          haveEdit: true,
          value: 0,
        },
        api: {
          haveView: true,
          haveEdit: true,
          value: 0,
        },
      },
      actionMap: {
        none: 0,
        read: 1,
        update: 2,
      },
      actionMapReverse: {
        0: 'none',
        1: 'read',
        2: 'update',
      },
    }
  },
  mounted() {
    if (this.$store.state.app.isMaster(this.$store)) {
      this.defaultAbility.system = {
        haveView: false,
        haveEdit: true,
        value: 0,
      }
      this.$forceUpdate()
    }

    if (this.ability.length === 1 && this.ability[0].subject === 'all' && this.ability[0].action === 'manage') {
      this.master = true
    } else {
      for (let i = 0; i < this.ability.length; i += 1) {
        const ability = this.ability[i]
        if (ability.subject in this.defaultAbility) {
          this.defaultAbility[ability.subject].value = this.actionMap[ability.action]
        }
      }
    }

    const myRules = this.$ability.rules
    if (myRules.length > 0 && myRules[0].subject !== 'all' && myRules[0].action !== 'manage') {
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in this.defaultAbility) {
        const rule = myRules.find(v => v.subject === key)
        if (rule === undefined) {
          delete this.defaultAbility[key]
        } else if (rule.action === 'read') {
          this.defaultAbility[key].haveEdit = false
        }
      }
    }
  },
  methods: {
    isCannotCreateAgent(v) {
      if (v.length === 1 && v[0].subject === 'all' && v[0].action === 'manage') {
        const { rules } = this.$ability
        return !(rules.length === 2 && rules[0].subject === 'all' && rules[0].action === 'manage')
      }
      return false
    },
    buildPermission(v) {
      if (this.isCannotCreateAgent(v)) {
        return this.getAll()
      }
      return v
    },
    getAll() {
      return [
        {
          action: 'read',
          subject: 'dashboard',
        },
        {
          action: 'read',
          subject: 'summary',
        },
        {
          action: 'update',
          subject: 'member',
        },
        {
          action: 'update',
          subject: 'invoice',
        },
        {
          action: 'read',
          subject: 'report',
        },
        {
          action: 'update',
          subject: 'lotto',
        },
        {
          action: 'update',
          subject: 'api',
        },
      ]
    },
    onSubmit() {
      if (this.master) {
        this.$emit('onSubmit', [{
          action: 'manage',
          subject: 'all',
        }])
      } else {
        const ability = []
        Object.entries(this.defaultAbility).forEach(([k, v]) => {
          const value = v.value * 1
          if (value !== 0) {
            ability.push({
              subject: k,
              action: this.actionMapReverse[value],
            })
          }
        })
        this.$emit('onSubmit', ability)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
//.customSpace {
//  margin-top: 0.65rem;
//  margin-bottom: 0.65rem;
//}

.inlineSpace {
  display: flex;
  flex-wrap: wrap;
  text-align: end;
  justify-content: flex-start;
  align-items: center;

  & > * {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 1.5rem;
  }
}

@media (max-width: 576px) {
  .inlineSpace {
    & > * {
      margin-left: 0.35rem;
    }
  }
}

@media (max-width: 400px) {
  .inlineSpace {
    & > * {
      margin-left: 0.5rem;
    }
  }
}

</style>
